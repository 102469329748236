import React from 'react';
import Img from 'gatsby-image';
import {useStaticQuery, graphql} from 'gatsby';

const Image = ({imageKey, alt}) => {
  const data = useStaticQuery(graphql`
    query {
      nationaleNederlanden: file(relativePath: { eq: "companies/nationale_nederlanden.png" }) {
        childImageSharp {
          fixed(height: 65, quality: 100) {
            ...GatsbyImageSharpFixed_withWebp_noBase64
          }
        }
      }
      warta: file(relativePath: { eq: "companies/warta.png" }) {
        childImageSharp {
          fixed(height: 65, quality: 100) {
            ...GatsbyImageSharpFixed_withWebp_noBase64
          }
        }
      }
      orange: file(relativePath: { eq: "companies/orange.png" }) {
        childImageSharp {
          fixed(height: 65, quality: 100) {
            ...GatsbyImageSharpFixed_withWebp_noBase64
          }
        }
      }
      uniCredit: file(relativePath: { eq: "companies/uni_credit.png" }) {
        childImageSharp {
          fixed(height: 65, quality: 100) {
            ...GatsbyImageSharpFixed_withWebp_noBase64
          }
        }
      }
      pbs: file(relativePath: { eq: "companies/pbs.png" }) {
        childImageSharp {
          fixed(height: 65, quality: 100) {
            ...GatsbyImageSharpFixed_withWebp_noBase64
          }
        }
      }
      geoPlace: file(relativePath: { eq: "companies/geo_place.png" }) {
        childImageSharp {
          fixed(height: 65, quality: 100) {
            ...GatsbyImageSharpFixed_withWebp_noBase64
          }
        }
      }
      hdi: file(relativePath: { eq: "companies/hdi.png" }) {
        childImageSharp {
          fixed(height: 65, quality: 100) {
            ...GatsbyImageSharpFixed_withWebp_noBase64
          }
        }
      }
    }
  `);

  return <Img fixed={data[imageKey].childImageSharp.fixed} style={{width: '100%'}} imgStyle={{objectFit: 'contain'}} alt={alt}/>;
};

export default [
  {
    name: 'nationaleNederlanden',
    image: () => <Image alt="Nationale Nederlanden" imageKey="nationaleNederlanden"/>,
  },
  {
    name: 'warta',
    image: () => <Image alt="Warta" imageKey="warta"/>,
  },
  {
    name: 'orange',
    image: () => <Image alt="Orange" imageKey="orange"/>,
  },
  {
    name: 'uniCredit',
    image: () => <Image alt="Uni Credit" imageKey="uniCredit"/>,
  },
  {
    name: 'bankPbs',
    image: () => <Image alt="Bank PBS" imageKey="pbs"/>,
  },
  {
    name: 'geoPlace',
    image: () => <Image alt="Geo Place" imageKey="geoPlace"/>,
  },
  {
    name: 'hdi',
    image: () => <Image alt="HDI" imageKey="hdi"/>,
  },
];
