import React, {useEffect, useState, memo} from 'react';
import {graphql} from 'gatsby';
import styled from 'styled-components';

import Carousel, {consts} from 'react-elastic-carousel';
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome';
import {faChevronLeft, faChevronRight} from '@fortawesome/free-solid-svg-icons';

import {Trans} from 'gatsby-plugin-react-i18next';
import {checkWidth, registerResizeHandler, unregisterResizeHandler} from '../utils/windowWidth';

import companies from '../models/companies';

import PageWrapper from '../containers/PageWrapper';
import ContactForm from '../modules/ContactForm';

import ImageSection from '../components/ImageSection';
import Section from '../components/Section';
import HorizontalCards from '../components/HorizontalCards';
import UnderlineHeading from '../components/UnderlineHeading';
import SectionDescription from '../components/SectionDescription';

const HomePage = ({data}) => {
  const [isMobile, setMobileState] = useState(false);

  useEffect(() => {
    setMobileState(checkWidth(760));
    registerResizeHandler(760, setMobileState);

    return () => {
      unregisterResizeHandler(760, setMobileState);
    };
  }, []);

  return (
    <PageWrapper>
      <ImageSection fluid={data.mainPhoto.childImageSharp.fluid}/>

      <Section>
        <UnderlineHeading>
          <Trans>professional-digitalization-services</Trans>
        </UnderlineHeading>
        <SectionDescription>
          <Trans>professional-digitalization-services-abstract</Trans>
        </SectionDescription>
      </Section>

      <Section theme="dark">
        <UnderlineHeading>
          <Trans>company-solutions</Trans>
        </UnderlineHeading>

        <HorizontalCards
          items={[
            {
              fluid: data.intranets.childImageSharp.fluid,
                heading: <Trans>Intranets</Trans>,
              description: <Trans>Intranets-short-desc</Trans>,
              link: '/solutions/intranets',
            },
            {
              fluid: data.customerPlatforms.childImageSharp.fluid,
                heading: <Trans>Customer platforms</Trans>,
                description: <Trans>Customer platforms short desc</Trans>,
              link: '/solutions/customer-platforms',
            },
            {
              fluid: data.partnerPortals.childImageSharp.fluid,
                heading: <Trans>Partner portals</Trans>,
              description: 'Manage your agents and partners networks. Share selling tools, monitor revenue, and more',
              link: '/solutions/partner-portals',
            },
            {
              fluid: data.integrationPlatforms.childImageSharp.fluid,
              heading: <Trans>Integration platforms</Trans>,
              description: 'One platform to rule them all-integrate data and capabilities of various systems in one flexible system',
              link: '/solutions/integration-platforms',
            },
          ]}
        />
      </Section>

      <Section>
        <Carousel
          itemsToShow={isMobile ? 1 : 5}
          itemsToScroll={isMobile ? 1 : 5}
          renderPagination={({pages, activePage, onClick}) => (
            <PaginationContainer>
              {pages.map((page) => (
                <PaginationItem key={page} isActive={activePage === page} onClick={() => onClick(page)}/>
              ))}
            </PaginationContainer>
          )}
          renderArrow={({type, onClick, isEdge}) => (
            <ArrowButton onClick={onClick} disabled={isEdge}>
              {type === consts.PREV ? <FontAwesomeIcon icon={faChevronLeft}/> : <FontAwesomeIcon icon={faChevronRight}/>}
            </ArrowButton>
          )}
        >
          {companies.map(({name, image}) => (
            <Item key={name}>{image()}</Item>
          ))}
        </Carousel>
      </Section>

      <Section theme="dark">
        <UnderlineHeading><Trans>Contact Us</Trans></UnderlineHeading>
        <SectionDescription>
          <Trans>Contact Us Hint</Trans>
        </SectionDescription>
        <ContactForm/>
      </Section>
    </PageWrapper>
  );
};

const PaginationContainer = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  margin-top: 1rem;
`;

const PaginationItem = styled.div`
  width: 13px;
  height: 13px;
  border: 2px solid #39b54a;
  background: ${({isActive}) => (isActive ? '#39b54a' : 'white')};
  position: relative;
  margin: 0.5rem;
  border-radius: 100%;
  box-sizing: border-box;
  cursor: pointer;

  :hover {
    background: #39b54a;
  }
`;

const Item = styled.div`
  width: 100%;

  @media screen and (min-width: 760px) {
    margin-right: 1rem;
  }
`;

const ArrowButton = styled.button`
  background: transparent;
  border: none;
  font-size: 2rem;
  color: #39b54a;

  :disabled {
    color: #c0c0c0;
  }
`;

export default memo(HomePage);

export const query = graphql`
  query {
    mainPhoto: file(relativePath: { eq: "mainpage/main_photo.png" }) {
      childImageSharp {
        fluid(maxWidth: 2500, quality: 100) {
          ...GatsbyImageSharpFluid_withWebp_noBase64
        }
      }
    }
    intranets: file(relativePath: { eq: "mainpage/cards/intranets.png" }) {
      childImageSharp {
        fluid(maxWidth: 150, quality: 100) {
          ...GatsbyImageSharpFluid_withWebp_noBase64
        }
      }
    }
    customerPlatforms: file(relativePath: { eq: "mainpage/cards/customer_platforms.png" }) {
      childImageSharp {
        fluid(maxWidth: 150, quality: 100) {
          ...GatsbyImageSharpFluid_withWebp_noBase64
        }
      }
    }
    partnerPortals: file(relativePath: { eq: "mainpage/cards/partner_portals.png" }) {
      childImageSharp {
        fluid(maxWidth: 150, quality: 100) {
          ...GatsbyImageSharpFluid_withWebp_noBase64
        }
      }
    }
    integrationPlatforms: file(relativePath: { eq: "mainpage/cards/integration_platforms.png" }) {
      childImageSharp {
        fluid(maxWidth: 150, quality: 100) {
          ...GatsbyImageSharpFluid_withWebp_noBase64
        }
      }
    }
  }
`;
